html {
  height: 100svh;
  width: 100svw;
  overflow: hidden;
}

:root {
  --content-padding : 4px;
  --border-radius-a: 6px;
  --border-radius-b: 10px;
}

.dark-purple-theme {
  --highlighted-nav-item-background: #a17bf8;
  --highlighted-nav-item-text: #f1ebff;
  --section-alternate-background: #0b0712;
  --primary-highlight: #f8f6ff;
}
.light-purple-theme {
  --highlighted-nav-item-background: #7337ff;
  --highlighted-nav-item-text: #f1ebff;
  --section-alternate-background: #e2deeb;
  --primary-highlight: #4700ee;
}

body {
  margin: 0;
  height: 100%;
  width: 100%;
  background-color: var(--surface-ground);
}

* {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}


#skipnav {
  background: var(--primary-color);
  height: 30px;
  z-index: 500;
  left: 50%;
  padding: 8px;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s;
  text-decoration: underline;
  cursor: pointer;
  &:focus {
    transform: translateY(0%);
  }
}

.fade {
  transition: all 2s ease;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.rot-45 {
  transform: rotate(45deg);
}

.section-header {
  font-size: 1.8em;
  text-align: center;
  font-weight: 500;
}

.section-subheader {
  font-size: 1.4em;
  text-align: center;
  font-weight: 300;
  color: var(--text-color-secondary);
}

.primary-text {
  color: var(--text-color)
}

.secondary-text {
  color: var(--text-color-secondary);
}
